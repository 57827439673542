// ** MUI Imports
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Magnify from 'mdi-material-ui/Magnify'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import { useCurrentUser } from '@1kit/ui/data/user'
import { Status } from 'utils/apiv2/types'

function CollectibleFilter({ filter, setFilter, mobile }) {
  const { data: user } = useCurrentUser()
  return (
    <Card sx={{ borderRadius: 1, boxShadow: mobile ? 'none' : '0px 2px 10px 0px rgba(20, 21, 33,  0.18)' }}>
      <CardHeader title="Search & Filter Rewards" sx={{ color: 'text.primary' }} />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <InputLabel id="game-search-label">{'Search Rewards'}</InputLabel>
              <OutlinedInput
                label="Search Rewards"
                placeholder="Search"
                value={filter.searchText}
                onChange={(e) => { setFilter({ ...filter, searchText: e.target.value }) }}
                type="text"
                startAdornment={(
                  <InputAdornment position="start">
                    <IconButton
                      edge="start"
                    >
                      <Magnify />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item container xs={12} md={7} spacing={6}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="game-genre-label">{'Game Genre'}</InputLabel>
                <Select label="Game Genre" defaultValue={filter.gameGenre} id="game-genre" labelId="game-genre-label" onChange={(e) => { setFilter({ ...filter, gameGenre: e.target.value }) }}>
                  <MenuItem value="all">{'All Genres'}</MenuItem>
                  <MenuItem value="RPG">{'RPG'}</MenuItem>
                  <MenuItem value="Platformer">{'Platformer'}</MenuItem>
                  <MenuItem value="Puzzles">{'Puzzles'}</MenuItem>
                  <MenuItem value="Casual">{'Casual'}</MenuItem>
                  <MenuItem value="Indie">{'Indie'}</MenuItem>
                  <MenuItem value="Action">{'Action'}</MenuItem>
                  <MenuItem value="FPS">{'FPS'}</MenuItem>
                  <MenuItem value="Arcade">{'Arcade'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>{'Show All Rewards'}</InputLabel>
                <Select
                  label="Show All Rewards"
                  defaultValue={filter.category}
                  onChange={(e) => {
                    if (e.target.value === Status.SUBMITTED) {
                      setFilter({ ...filter, status: Status.SUBMITTED })
                    } else {
                      setFilter({ ...filter, category: e.target.value, status: 'all' })
                    }
                  }}
                >
                  <MenuItem value="all">{'Show All Rewards'}</MenuItem>
                  <MenuItem value="new-collectibles">{'New Rewards'}</MenuItem>
                  <MenuItem value="expiring-soon">{'Expiring Soon'}</MenuItem>
                  {user?.role === 'admin' && <MenuItem value={Status.SUBMITTED}>{'Submitted'}</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CollectibleFilter
