import { useRouter } from 'next/router'

import CircularProgress from '@mui/material/CircularProgress'
import { useAuth } from '@1kit/ui/context/auth'
import { useCollectibleQuery } from 'data/rewards/query'
import CollectiblePage from 'components/collectible/CollectiblePage'

function CollectibleRoute() {
  const router = useRouter()
  const { data: collectible, isLoading: loading } = useCollectibleQuery(+router.query.handle)
  const { user } = useAuth()

  if (loading) {
    return <CircularProgress disableShrink sx={{ position: 'absolute', top: '50%', left: '50%' }} />
  }

  return (
    <CollectiblePage
      collectible={collectible}
      user={user}
      game={collectible?.game}
    />
  )
}

export default CollectibleRoute
