import { styled } from '@1kit/ui/theme'

function EmptyState() {
  return (
    <Container>
      <TextLabel>You have encountered</TextLabel>
      <TextLabel>an Empty Page!</TextLabel>
      <Logo src="/images/empty_logo.png" alt="empty state" />
      <TextLabel>Try adjusting your search or filters to</TextLabel>
      <TextLabel> get better results.</TextLabel>
    </Container>
  )
}

export default EmptyState

const TextLabel = styled('div')`
    font-size:16px;
    font-weight: 600;
`

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    max-width:400px;
    align-items: center;
    margin: 20px auto;
    padding: 20px 50px;
`

const Logo = styled('img')`
    width: 140px;
    height:140px;
    margin: 20px;
`
