import { styled } from '@1kit/ui/theme'
import { Benefit } from '@1kit/ui/types/Benefit'
import Logo from './Logo'
import AmazonInfo from './AmazonInfo'
import Rewards from './Rewards'
import ClaimCard from './ClaimCard'
import WhatElse from './WhatElse'
import TermsAndConditions from './TermsAndConditions'

export type Props = {
  benefit: Benefit,
  className?: string
}

const AmazonClaimBenefitPage = styled(({ benefit, className } : Props) => (
  <div className={className}>
    <div className="amazon-content-container">
      <Logo benefit={benefit} />
      <Rewards benefit={benefit} />
      <AmazonInfo />
      <ClaimCard benefit={benefit} />
      <WhatElse />
      <TermsAndConditions />
    </div>
  </div>
))`
  display: flex;
  justify-content: center;
  padding: 16px;

  .amazon-content-container {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`

export default AmazonClaimBenefitPage
