import { request } from '@1kit/api'
import { Claim } from './types'
import { transformClaimV2toClaimV1 } from './transformations'

export function startRewardClaim(rewardId: number) {
  return request<Claim>(`/rewards/${rewardId}/claim`, {
    method: 'POST',
    body: {},
  })
}

export function startRewardClaimAsClaimV1(rewardId: number) {
  return startRewardClaim(rewardId).then(transformClaimV2toClaimV1)
}
