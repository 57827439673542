import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { styled } from '@1kit/ui/theme'
import NextImage from 'next/image'
import claimingPng from 'public/images/collectibles/claiming.png'
import { mobile } from '@1kit/ui/css/media'

const ModalContentWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.surface.paper};
  box-shadow: 0px 2px 2px 0px ${({ theme }) => theme.surface.paper};
  border-radius: 20px;
  width: 80%;
  padding-top: 55px;
  padding-bottom: 55px;
  padding-left: 130px;
  padding-right: 130px;
  max-width: 850px;
  max-height: 95vh;
  overflow-y: auto;
  ${mobile} {
    width: 90%;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }
`

const ModalContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
`

const ImageWrapper = styled(Box)`
  position: relative;
  aspect-ratio: 2;
  width: 100%;
  margin-bottom: 32px;
`

function FirstTimeModal({ onClose, open }) {
  return (
    <Modal open={open} onClose={() => null} disableRestoreFocus disableEnforceFocus>
      <ModalContentWrapper>
        <ModalContent>
          <Typography variant="h4">{'You’re in!'}</Typography>
          <Typography variant="h6" sx={{ mb: 6 }}>{'Now it’s time to claim some goodies.'}</Typography>
          <ImageWrapper>
            <NextImage src={claimingPng} layout="fill" objectFit="contain" />
          </ImageWrapper>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6">
              {'1. Find some content you like'}
            </Typography>
            <Typography variant="h6">
              {'2. Click “Claim”'}
            </Typography>
            <Typography variant="h6">
              {'3. Click “Get in-game content”'}
            </Typography>
            <Typography variant="h6">
              {'4. Follow the instructions to redeem your stuff!'}
            </Typography>
          </Box>
          <Button variant="outlined" onClick={onClose}>
            {'Start Claiming'}
          </Button>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  )
}

export default FirstTimeModal
