/* eslint-disable react/jsx-props-no-spreading */
import useMediaQuery from '@mui/material/useMediaQuery'
import { useEffect, useCallback } from 'react'

// ** Layout Imports
// !Do not remove this Layout import
import Layout from 'materialize/layouts/Layout'

// ** Navigation Imports
import VerticalNavItems from 'navigation/vertical'
import HorizontalNavItems from 'navigation/horizontal'

// ** Component Import
// Uncomment the below line (according to the layout type) when using server-side menu
// import ServerSideVerticalNavItems from './components/vertical/ServerSideNavItems'

// ** MUI Imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// ** Hook Import
import { useSettings } from 'materialize/hooks/useSettings'
import { useAuth } from '@1kit/ui/context/auth'
import { useFlags } from '@1kit/ui/flags'
import VerticalAppBarContent from './components/vertical/AppBarContent'
import HorizontalAppBarContent from './components/horizontal/AppBarContent'

function MenuFooter() {
  useEffect(() => {
    const script = window.document.createElement('script')
    script.src = 'https://1kin.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=cafd0c85'
    script.async = true
    window.document.body.appendChild(script)
    const element = window.document.getElementById('atlwdg-trigger')
    if (element) {
      element.style.left = '110%'
    }
    return () => {
      window.document.body.removeChild(script)
    }
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
      }}
    >
      <Typography sx={{ mr: 2, px: 8, py: 6 }}>{`© ${new Date().getFullYear()}, GR1D Network `}</Typography>
    </Box>
  )
}

function UserLayout({ children }) {
  // ** Hooks
  const { settings, saveSettings } = useSettings()
  const auth = useAuth()
  const flags = useFlags()

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const horizontalAppBarContent = useCallback(() => (
    <HorizontalAppBarContent hidden={hidden} settings={settings} saveSettings={saveSettings} />
  ), [hidden, settings, saveSettings])

  const verticalAppBarContent = useCallback((props) => (
    <VerticalAppBarContent
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      {...props}
    />
  ), [hidden, settings, saveSettings])

  const afterVerticalNavMenuContent = useCallback(() => <MenuFooter />, [])

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      {...(settings.layout === 'horizontal'
        ? {
          // ** Navigation Items
          horizontalNavItems: HorizontalNavItems(),

          // ** AppBar Content
          horizontalAppBarContent,
        }
        : {
          // ** Navigation Items
          verticalNavItems: VerticalNavItems(auth.user, flags),

          // ** AppBar Content
          verticalAppBarContent,

          afterVerticalNavMenuContent,
        })}
    >
      {children}
    </Layout>
  )
}

export default UserLayout
