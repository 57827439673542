import { request } from '@1kit/api/request'
import { Game } from './types'

// TODO: api-server | use request/requestPublic from @1kit/api
// TODO: api-server | allow filtering?
export function fetchGames() {
  return request<Game[]>('/games')
}

export function fetchGame(id: number) {
  return request<Game>(`/games/${id}`)
}

export function createGame(game: Game) {
  return request<Game>('/games', {
    method: 'POST',
    body: game,
  })
}

export function updateGame(id: number, game: Game) {
  return request<Game>(`/games/${id}`, {
    method: 'PATCH',
    body: game,
  })
}
