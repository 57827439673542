import { useEffect, useState } from 'react'
import NextLink from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

// ** Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'

const HeroCardBox = styled(Box)(() => ({
  position: 'relative',
  borderRadius: 0,
  height: 240,
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  '& .overlay': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 1,
    background: 'linear-gradient(359.85deg, #101121 9.02%, rgba(16, 17, 33, 0) 44.23%)',
  },
  '& .info': {
    position: 'absolute',
    left: 0,
    bottom: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,
  },
}))

const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}))

const ThumbnailSliderWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 96,
  marginTop: `${theme.spacing(2)}`,
}))

function HeroCard({ game }) {
  return (
    <HeroCardBox sx={{ width: '100%' }}>
      {/* eslint-disable-next-line */}
        <img src={game.image} alt={game.title} />
      <Box className="overlay" />
      <Box className="info">
        <Typography variant="h5" sx={{ mt: 2, color: 'white', mb: 1 }}>
          {game.title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'white', mb: 2 }}>
          {game.description}
        </Typography>
        <NextLink href={`/collectibles/${game.id}`}>
          <Button variant="contained">{'Get in-game content'}</Button>
        </NextLink>
      </Box>
    </HeroCardBox>
  )
}

function ThumbCard({ active, game, onClick }) {
  return (
    <Box
      sx={{
        cursor: 'pointer', borderRadius: 0.5, height: 96, position: 'relative', overflow: 'hidden',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          background: 'black',
          opacity: 0.5,
          zIndex: 0,
        }}
      />
      <StyledImage src={game.thumbnail} alt={game.title} sx={{ position: 'absolute', zIndex: 1 }} />
      {!active && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'black',
            opacity: 0.5,
            zIndex: 2,
          }}
        />
      )}
    </Box>
  )
}

function FeaturedContentSliderMobile({ featuredRewards }) {
  const [swiper, setSwiper] = useState(null)
  const [thumbSwiper, setThumbSwiper] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(-1)

  useEffect(() => {
    setTimeout(() => {
      setCurrentIndex(0)
    }, 10)
  }, [])

  const navigate = (index) => {
    setCurrentIndex(index)
    swiper.slideTo(index, 1000)
    thumbSwiper.slideToLoop(index, 1000)
  }

  const handleThumbSlideChange = (e) => {
    setCurrentIndex(e.realIndex)
    swiper.slideTo(e.realIndex, 1000)
  }

  return (
    <div>
      <Swiper
        modules={[EffectFade]}
        effect="fade"
        loop
        allowTouchMove={false}
        speed={1000}
        slidesPerView={1}
        onSwiper={(swiperItem) => setSwiper(swiperItem)}
      >
        {featuredRewards.map((game) => (
          <SwiperSlide key={game.id}>
            <HeroCard game={game} />
          </SwiperSlide>
        ))}
      </Swiper>
      <ThumbnailSliderWrapper>
        <Swiper
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          effect="fade"
          loop
          speed={1000}
          spaceBetween={10}
          slidesPerView={2.4}
          onSlideChange={(e) => handleThumbSlideChange(e)}
          onSwiper={(swiperItem) => setThumbSwiper(swiperItem)}
        >
          {featuredRewards.map((game, index) => (
            <SwiperSlide key={game.id}>
              <ThumbCard active={index === currentIndex} game={game} onClick={() => navigate(index)} />
            </SwiperSlide>
          ))}
        </Swiper>
      </ThumbnailSliderWrapper>
    </div>
  )
}

export default FeaturedContentSliderMobile
