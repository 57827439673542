import Button from '@mui/material/Button'
import PersonOutline from '@mui/icons-material/PersonOutline'
import { useState } from 'react'

import LoginDialog from '@1kit/ui/components/LoginDialog'

export default function UserSignInButton() {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

  return (
    <>
      <Button variant="outlined" startIcon={<PersonOutline />} onClick={() => setIsLoginModalOpen(true)}>{'Sign in'}</Button>
      <LoginDialog
        open={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  )
}
