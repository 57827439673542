import { request, requestPublic } from '@1kit/api/request'

export default {
  getUser() {
    return request(`/end-users/getUserDetail?_t=${Date.now()}`)
  },
  async updateUser(body) {
    return request('/end-users/updateMe', {
      method: 'POST',
      body,
    })
  },
  async getGameNfts(userId) {
    return request('/end-users/getGameNfts', {
      method: 'POST',
      body: { userId },
    })
  },
  async addGameNft(body) {
    return request('/end-users/addGameNft', {
      method: 'POST',
      body,
    })
  },
  async removeGameNft(body) {
    return request('/end-users/removeGameNft', {
      method: 'POST',
      body,
    })
  },
  async addNewWallet(body) {
    return request('/end-users/addWallet', {
      method: 'POST',
      body,
    })
  },
  async removeWallet(body) {
    return request('/end-users/removeWallet', {
      method: 'POST',
      body,
    })
  },
  async getNetworks() {
    const result = requestPublic('/networks')

    return result.data.map((o) => ({
      ...o.attributes,
      id: o.id,
    }))
  },
  async requestNonce(body) {
    return requestPublic('/end-users/requestNonce', {
      method: 'POST',
      body,
    })
  },
  async register(body) {
    return requestPublic('/end-users/register', {
      method: 'POST',
      body,
    })
  },
  async verifyWaitlistUserEmail(email) {
    return requestPublic('/end-users/verifyWaitlistUserEmail', {
      method: 'POST',
      body: { email },
    })
  },
  async auth(body) {
    return requestPublic('/end-users/auth', {
      method: 'POST',
      body,
    })
  },
  async checkPasswordFromEmail(body) {
    return requestPublic('/end-users/checkPasswordFromEmail', {
      method: 'POST',
      body,
    })
  },
  async getPolicies() {
    return request('/policies')
  },
  async changePassword(body) {
    return request('/end-users/changePassword', {
      method: 'POST',
      body,
    })
  },
  async verify(body) {
    return requestPublic('/end-users/verify', {
      method: 'POST',
      body,
    })
  },
  async updateUserFromWeb3Auth(body) {
    return request('/end-users/updateUserFromWeb3Auth', {
      method: 'POST',
      body,
    })
  },
  async setupPassword(token, password) {
    return requestPublic('/end-users/setupPassword', {
      method: 'POST',
      body: { token, password },
    })
  },

  async emailVerify(token) {
    return requestPublic(`/end-users/email-verify?token=${token}`, {
      method: 'GET',
    })
  },

  async getBenefitInfo(benefitId) {
    return requestPublic(`/benefits/${benefitId}`, {
      method: 'GET',
    })
  },
  async handleAmazonRedemption(benefitId, amazonToken) {
    return request(`/benefits/${benefitId}/redeem`, {
      method: 'POST',
      headers: {
        'x-amazon-token': amazonToken,
      },
    })
  },
  // two step verification
  async deleteTwostepNumber() {
    return request('/end-users/deleteTwostepNumber', {
      method: 'POST',
    })
  },
  async setTwostepNumber(mobileNumber) {
    return request('/end-users/setTwostepNumber', {
      method: 'POST',
      body: { mobileNumber },
    })
  },
  async verifyPhoneNumber(phoneNumber) {
    return request('/end-users/verifyPhoneNumber', {
      method: 'POST',
      body: { phoneNumber },
    })
  },
  async verifyPhoneNumberCode(code) {
    return request('/end-users/verifyPhoneNumberCode', {
      method: 'POST',
      body: { code },
    })
  },
  async setTwostepStatus(status) {
    return request('/end-users/setTwostepStatus', {
      method: 'POST',
      body: { status },
    })
  },
  async checkUserName(displayname) {
    return request('/end-users/checkUserName', {
      method: 'POST',
      body: { displayname },
    })
  },
  async resendTwoStepCode(resendToken) {
    return requestPublic('/end-users/resendTwostepCode', {
      method: 'POST',
      body: { resendToken },
    })
  },
  async verifyTwoStepCode(code, resendToken) {
    return requestPublic('/end-users/verifyTwostep', {
      method: 'POST',
      body: { code, resendToken },
    })
  },
  async collectEmail(email, token) {
    return requestPublic('/end-users/setUserEmail', {
      method: 'POST',
      body: { email, token },
    })
  },

  async verifyEmailToken(token) {
    return requestPublic(`/end-users/email-verify?token=${token}`)
  },

  async getGames() {
    return request('/end-users/games')
  },
  async getGameCollectiblesById(id) {
    return request(`/end-users/games/${id}/collectibles`)
  },

  async getCollectible(id) {
    return requestPublic(`/end-users/collectibles/${id}`)
  },
  async completeClaim(id) {
    return request(`/end-users/collectibles/${id}/completeClaim`, {
      method: 'POST',
    })
  },
  // TODO: remove this onlyPending logic when flags.inventoryRefresh flag is removed
  async getMyCollectibles(onlyPending = false) {
    if (onlyPending) {
      return request('/end-users/collectibles/me?filter[status]=Pending')
    }

    return request('/end-users/collectibles/me')
  },
  async getCollectibles(body) {
    return requestPublic(`/end-users/collectibles?${new URLSearchParams(body).toString()}`)
  },
  async createStripePaymentIndentUsingGooglePay(body) {
    return request('/end-users/createStripePaymentIndentUsingGooglePay', {
      method: 'POST',
      body,
    })
  },
  async chargeStripe(body) {
    return request('/end-users/chargeStripe', {
      method: 'POST',
      body,
    })
  },
  /**
   * Asynchronously generates a referral link for an end-user.
   * This function makes a POST request to the '/end-users/referral' endpoint.
   * It does not take any parameters and returns a Promise that resolves
   * to the response of the request.
   *
   * @async
   * @function generateReferralLink
   * @returns {Promise<Object>}
   */
  async generateReferralLink() {
    return request('/end-users/referral', {
      method: 'POST',
    })
  },
}
