// ** React Imports
import { useEffect } from 'react'

// ** Layout Import
import BlankLayout from 'materialize/layouts/BlankLayout'

function DiscordCallback() {
  useEffect(() => {
    const fragment = new URLSearchParams(window.location.hash.slice(1))
    if (window.opener) {
      const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')]
      window.opener.postMessage({ accessToken, tokenType })
      window.close()
    } else {
      window.alert('No opener')
    }
  }, [])

  return <div />
}
DiscordCallback.getLayout = (page) => <BlankLayout>{page}</BlankLayout>

export default DiscordCallback
