import { styled } from '@1kit/ui/theme'
import Button from '@1kit/ui/atoms/Button'
import { useState } from 'react'
import OnePassDialog from 'components/OnePassDialog'
import { Typography } from '@mui/material'
import { User } from '@1kit/types'
import { SubscriptionType } from '@1kit/types/Subscription'

const CreditsText = styled(Typography)``

export type Props = {
  user: User,
  className?: string
}

const CreditsButton = styled(({ user, className } : Props) => {
  const isUserUnlimited = user.subscription?.type === SubscriptionType.UNLIMITED
  const availableCredits = user.availableCredits || 0

  const [shouldOnePassDialogBeOpen, setShouldOnePassDialogBeOpen] = useState(false)
  return (
    <>
      <OnePassDialog
        open={shouldOnePassDialogBeOpen}
        onClose={() => setShouldOnePassDialogBeOpen(false)}
      />
      <Button
        onClick={() => setShouldOnePassDialogBeOpen(true)}
        variant="contained"
        color="inherit"
        sx={{ px: 2 }}
        className={className}
      >
        <CreditsText variant="body2">
          {'Credits: '}
        </CreditsText>
        <CreditsText variant="body2" fontSize={isUserUnlimited ? '20px' : undefined}>
          {
            isUserUnlimited ? '∞' : availableCredits
          }
        </CreditsText>
      </Button>
    </>

  )
})`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    gap: ${({ theme }) => theme.spacing(1)};
    color: ${({ theme }) => theme.text.success};
  }

  ${CreditsText} {
    color: ${({ theme }) => theme.text.success};
  }
`

export default CreditsButton
