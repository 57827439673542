import BlankLayout from 'materialize/layouts/BlankLayout'
import { useFlags } from '@1kit/ui/flags'
import AmazonClaimBenefitPage from 'components/amazonClaimBenefitPage'
import { Benefit } from '@1kit/ui/types/Benefit'
import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'
import api from 'utils/api'
import { usePromise } from '@1kit/ui/hooks/usePromise'

function AmazonClaimPage() {
  const router = useRouter()
  const flags = useFlags()
  const [benefit, setBenefit] = useState<Benefit | null>(null)

  const { benefitId } = router.query

  // stuff like this is why typescript kind of exists but also these are the cases where I just like... don't think it really matters. :sweat_smile:
  const singleBenefitId = Array.isArray(benefitId) ? benefitId[0] : benefitId || null

  usePromise(async () => {
    if (!flags.amazinAmazon || !benefitId) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const benefitResponse = await api.getBenefitInfo(singleBenefitId) as Benefit

    setBenefit(benefitResponse)
  }, [benefitId])

  return flags.amazinAmazon && benefit ? <AmazonClaimBenefitPage benefit={benefit} /> : 'Not available!'
}

AmazonClaimPage.getLayout = (page : ReactNode) => <BlankLayout>{page}</BlankLayout>
export default AmazonClaimPage
