/* eslint-disable @next/next/no-img-element */
import { styled } from '@1kit/ui/theme'
import {
  Card, CardContent as CardContentBase, Typography,
} from '@mui/material'

export type Props = {
  className?: string
}

const CardContent = styled(CardContentBase)``
const Image = styled.img``

const AmazonInfo = styled(({ className } : Props) => (
  <Card className={className}>
    <CardContent>
      <Typography variant="body1" textAlign="center">
        {'For owners of 1KIN 1PASS digital collectibles. Only available on'}
      </Typography>
      <Image src="/images/logos/amazon-full-white.svg" alt="Amazon icon" />
    </CardContent>
  </Card>
))`

  width: 100%;

  ${CardContent} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  ${Image} {
    object-fit: cover;
    height: 45px;
  }
`

export default AmazonInfo
