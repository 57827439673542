import { usePromise } from '@1kit/ui/hooks/usePromise'
import { useRouter } from 'next/router'
import { useAuth } from '@1kit/ui/context/auth'

function AuthGuard({ children, fallback }) {
  const { isLoading, user } = useAuth()
  const router = useRouter()

  usePromise(async () => {
    if (!isLoading && user === null) {
      router.push('/')
    }
  }, [user, isLoading])

  if (isLoading) {
    return fallback
  }

  return children
}

export default AuthGuard
