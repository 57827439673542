/* eslint-disable @stylistic/indent */
import { Collectible } from '@1kinlabs/ui-core/types/Collectible'
import { useQuery } from '@1kit/ui/data'
import { useMemo } from 'react'
import {
  fetchCollectiblesByGameId, fetchRewardCollectible, fetchRewardsByGameId, fetchRewardsCollectibles,
} from 'utils/apiv2/fetchRewards'
import { ClaimStatus as ClaimStatusV1 } from '@1kinlabs/ui-core/enums/ClaimStatus'
import { Status } from 'utils/apiv2/types'
import { User } from '@1kit/types'
import { CollectibleBlopBlop } from 'utils/apiv2/transformations'
import { useCurrentUser } from '@1kit/ui/data/user'

export function useCollectiblesQuery() {
  return useQuery({
    queryKey: ['/rewards'],
    async queryFn() {
      return fetchRewardsCollectibles()
    },
  })
}

export function useInProgressCollectiblesQuery() {
  const { data, ...rest } = useCollectiblesQuery()
  const filteredData = useMemo(() => data?.filter((collectible) => (
    collectible.claim && [
      ClaimStatusV1.IN_PROGRESS.toString(),
    ].includes(collectible.claimStatus)
  )), [data])

  return {
    data: filteredData,
    ...rest,
  }
}

type UserBlopBlop = User & { accountId: number, role: string }

export function useCollectiblesFilteredByStatusQuery(statuses: Status[], user: UserBlopBlop) {
  const { data = [], ...rest } = useCollectiblesQuery()
  const { filteredData, ownedByUser, claimedByUser } = useMemo(
    () => data.reduce<{
      filteredData: CollectibleBlopBlop[],
      ownedByUser: CollectibleBlopBlop[],
      claimedByUser: CollectibleBlopBlop[] }>((acc, collectible) => {
        const status = collectible.status as Status
        if (statuses.includes(status) && collectible.game?.status === Status.LIVE) {
          acc.filteredData.push(collectible)
        } else if (user && (user?.role === 'admin' || collectible.game?.accountId === user.accountId)) {
          acc.ownedByUser.push(collectible)
        } else {
          acc.claimedByUser.push(collectible)
        }
        return acc
      }, {
        filteredData: [],
        ownedByUser: [],
        claimedByUser: [],
      }),
    [data, statuses],
  )

  return {
    data: filteredData,
    claimedByUser,
    ownedByUser,
    ...rest,
  }
}

export function useCollectibleQuery(id: number) {
  return useQuery({
    queryKey: ['/rewards', id],
    async queryFn(): Promise<Collectible> {
      return fetchRewardCollectible(id)
    },
  })
}

export const useCollectiblesByGameIdQuery = (gameId: number | undefined) => useQuery({
  queryKey: [`/games/${gameId}/collectibles`],
  initialData: [],
  queryFn: () => fetchCollectiblesByGameId(gameId),
})

// eslint-disable-next-line max-len
export function useCollectiblesByGameIdFilteredByStatusQuery(gameId: number | undefined, statuses: Status[]) {
  const { data, ...rest } = useCollectiblesByGameIdQuery(gameId)
  const { filteredData, additionalData } = useMemo(
    () => data?.reduce<{
      filteredData: CollectibleBlopBlop[],
      additionalData: CollectibleBlopBlop[]
    }>((acc, collectible) => {
      const status = collectible.status as Status
      if (statuses.includes(status)) {
        acc.filteredData.push(collectible)
      } else {
        acc.additionalData.push(collectible)
      }
      return acc
    }, { filteredData: [], additionalData: [] }),
    [data, statuses],
  )

  return {
    data: filteredData,
    additionalData,
    ...rest,
  }
}

export const useRewardsQuery = () => useQuery({
  queryKey: ['/rewards'],
  initialData: [],
  queryFn: () => fetchRewardsCollectibles(),
})

export function useMyRewardsQuery() {
  const { data: user } = useCurrentUser()
  const { data, ...rest } = useRewardsQuery()
  const filteredData = useMemo(
    () => data?.filter((collectible) => collectible.game?.accountId === user?.accountId),
    [user?.accountId, data],
  )

  return {
    data: filteredData,
    ...rest,
  }
}

export const useRewardsByGameIdQuery = (gameId: number) => useQuery({
  queryKey: [`/games/${gameId}/rewards`],
  initialData: [],
  queryFn: () => fetchRewardsByGameId(gameId),
})
