import { useEffect } from 'react'
import { OnePassLogo } from '@1kit/ui/svg/1Pass'
import { Typography, Card } from '@mui/material'
import useCheckout from 'data/billing/useCheckout'
import Button from '@1kit/ui/atoms/Button'
import { SubscriptionFrequency, SubscriptionType } from '@1kit/types/Subscription'
import { styled } from '@1kit/ui/theme'
import Spinner from '@1kit/ui/atoms/Spinner'
import { PricingCardContent } from './PricingCardContent'
import { PricingCardBadge } from './PricingCardBadge'
import { PricingCardHeader } from './PricingCardHeader'
import { PricingCardHeaderTextContainer } from './PricingCardHeaderTextContainer'
import { PricingInfoSideBySide } from './PricingInfoSideBySide'
import { PricingCardList, PricingCardListItem } from './PricingCardList'

export type Props = {
  className?: string
  title: string
  subtitle: string
  isCurrent?: boolean
  isBestValue?: boolean
  showOnePassLogo?: boolean
  benefits: string[]
  type?: SubscriptionType
  frequency: SubscriptionFrequency
}

const PricingCard = styled(({
  className,
  isCurrent,
  isBestValue,
  showOnePassLogo,
  title,
  subtitle,
  benefits,
  type,
  frequency,
}: Props) => {
  const {
    mutate,
    isPending,
    isSuccess,
    data,
  } = useCheckout({ type, frequency })

  useEffect(() => {
    if (data?.url) {
      window.location.href = data.url
    }
  }, [data?.url])

  const pricingMap : Record<SubscriptionType, Record<SubscriptionFrequency, number>> = {
    [SubscriptionType.LIMITED]: {
      [SubscriptionFrequency.MONTHLY]: 9.99,
      [SubscriptionFrequency.ANNUAL]: 8.33,
    },
    [SubscriptionType.UNLIMITED]: {
      [SubscriptionFrequency.MONTHLY]: 19.99,
      [SubscriptionFrequency.ANNUAL]: 16.66,
    },
  }

  const btnDisabled = isPending || isCurrent || !type || false
  const btnVariant = isCurrent || !isBestValue ? 'outlined' : 'contained'
  const btnLabel = isCurrent ? 'Current' : 'Get Started'
  const price = type ? pricingMap[type][frequency]
    : 0
  return (
    <Card className={className}>
      {
        isBestValue && <PricingCardBadge>{'Best Value'}</PricingCardBadge>
      }
      <PricingCardHeader>
        <OnePassLogo visibility={showOnePassLogo ? 'visible' : 'hidden'} />
        <PricingCardHeaderTextContainer>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body1">
            {subtitle}
          </Typography>
        </PricingCardHeaderTextContainer>
      </PricingCardHeader>
      <PricingCardContent>
        <PricingInfoSideBySide price={price} frequency={frequency} />
        <div>
          <Button
            loading={isPending || isSuccess}
            onClick={mutate}
            fullWidth
            variant={btnVariant}
            disabled={btnDisabled}
            color="primary"
          >
            {btnLabel}
          </Button>
          <PricingCardList>
            {
              benefits.map((benefit) => (
                <PricingCardListItem key={benefit}>
                  {benefit}
                </PricingCardListItem>
              ))
            }
          </PricingCardList>
        </div>
      </PricingCardContent>
    </Card>
  )
})`
  width: 275px;
  display: flex;
  flex-direction: column;
  border: ${({ theme, isBestValue }) => (isBestValue
    ? `3px solid ${theme.border.primary.active}`
    : `1px solid ${theme.surface.paperLight}`)};
  position: relative;

  ${Spinner} {
    height: 100%;
  }
`

export default PricingCard
