/* eslint-disable @next/next/no-img-element */
import { styled } from '@1kit/ui/theme'
import {
  Card, CardContent as CardContentBase, List as BaseList, ListItem as BaseListItem, Typography,
} from '@mui/material'

export type Props = {
  className?: string
}

const CardContent = styled(CardContentBase)``
const List = styled(BaseList)``
const ListItem = styled(BaseListItem)``

const TermsAndConditions = styled(({ className } : Props) => (
  <Card className={className}>
    <CardContent>
      <Typography variant="h6">
        {'Terms and Conditions'}
      </Typography>
      <List>
        <ListItem>{'No returns or refunds are available'}</ListItem>
        <ListItem>{'No re-selling of your digital collectible (yet)'}</ListItem>
        <ListItem>{'No illegal stuff!'}</ListItem>
      </List>
    </CardContent>
  </Card>
))`

  width: 100%;

  ${CardContent} {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  ${List} {
    list-style-type: disc;
    list-style-position: inside;
  }

  ${ListItem} {
    display: list-item;
  }
`

export default TermsAndConditions
