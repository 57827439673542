/* eslint-disable @next/next/no-img-element */
import { styled } from '@1kit/ui/theme'
import {
  Card, CardContent as CardContentBase, Typography,
} from '@mui/material'

export type Props = {
  className?: string
}

const CardContent = styled(CardContentBase)``
const Image = styled.img``

const WhatElse = styled(({ className } : Props) => (
  <Card className={className}>
    <CardContent>
      <Typography variant="h5">
        {'What else can you find on 1KIN?'}
      </Typography>
      <Typography variant="h6">
        {'Frequent Digital Collectible Drops'}
      </Typography>
      <Image src="/images/amazon/collectibles_group_mobile.png" />
      <Typography variant="h6">
        {'Find Amazing New Games'}
      </Typography>
      <Image src="/images/amazon/publisher_logos_mobile.png" />
    </CardContent>
  </Card>
))`

  width: 100%;

  ${CardContent} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  ${Image} {
    object-fit: cover;
    width: 100%;
  }
`

export default WhatElse
