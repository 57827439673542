import { styled } from '@1kit/ui/theme'
import { Button, DialogActions } from '@mui/material'
import Dialog, { Props as DialogProps } from '@1kit/ui/components/Dialog'
import { useAuth } from '@1kit/ui/context/auth'
import { CollectibleBlopBlop } from 'utils/apiv2/transformations'
import { OnePassPlans } from './one-pass/OnePassPlans'

const OnePassDialogActions = styled(DialogActions)`
  place-content: center;
`

export type Props = DialogProps & {
  className?: string,
  collectible?: CollectibleBlopBlop
}

const Container = styled.div``

const OnePassDialog = styled(({
  className, open, onClose,
} : Props) => {
  const { user } = useAuth()

  return (
    <Dialog className={className} includeBanner open={open} onClose={onClose}>
      <Container>
        <OnePassPlans subscription={user?.subscription} />
        <OnePassDialogActions>
          <Button onClick={onClose}>{'Close'}</Button>
        </OnePassDialogActions>
      </Container>
    </Dialog>
  )
})`
  ${Container} {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
  }
`

export default OnePassDialog
