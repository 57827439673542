import PricingCard from 'components/cards/pricing-card/PricingCard'
import { styled } from '@1kit/ui/theme'
import { Subscription, SubscriptionFrequency, SubscriptionType } from '@1kit/types/Subscription'
import PricingFrequencyToggle from 'components/cards/pricing-card/PricingFrequencyToggle'
import { useState } from 'react'
import { mobile } from '@1kit/ui/css/media'

const PlanColumns = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(4)};
  flex-wrap: wrap;
  ${mobile} {
    flex-wrap: wrap-reverse;
  }
`

type Props = {
  subscription?: Subscription | null
}

export function OnePassPlans({ subscription }: Props) {
  const [frequency, setFrequency] = useState(
    subscription?.frequency || SubscriptionFrequency.ANNUAL,
  )

  return (
    <>
      <PricingFrequencyToggle
        frequency={frequency}
        onChange={(newFrequency) => setFrequency(newFrequency)}
      />
      <PlanColumns>
        <PricingCard
          title="Free"
          subtitle="You get one reward credit on us. Choose carefully! 😉"
          isCurrent={!subscription}
          benefits={
            [
              'Claim 1 reward',
              'Discover new games',
            ]
          }
          frequency={frequency}
        />
        <PricingCard
          title="Explorer"
          subtitle="A handful of reward credits each month."
          isCurrent={subscription?.type === SubscriptionType.LIMITED}
          showOnePassLogo
          frequency={frequency}
          type={SubscriptionType.LIMITED}
          benefits={[
            'Claim 5 rewards per month',
            'Exclusive content from hundreds of games',
            'New games added weekly',
          ]}
        />
        <PricingCard
          title="Unlimited"
          subtitle="Unlimited reward credits. Claim as much as you want!"
          isBestValue
          isCurrent={subscription?.type === SubscriptionType.UNLIMITED}
          showOnePassLogo
          frequency={frequency}
          type={SubscriptionType.UNLIMITED}
          benefits={[
            'Claim UNLIMITED rewards',
            'Exclusive collector-only content drops',
          ]}
        />
      </PlanColumns>
    </>
  )
}
