/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { styled } from '@1kit/ui/theme'
import {
  CardContent as CardContentBase, Typography as TypographyBase,
} from '@mui/material'
import AmazonButton from '@1kit/ui/components/SocialButtons/Amazon'
import ErrorIconBase from '@mui/icons-material/Error'
import { format } from '@1kit/ui/formatters/date'
import { Benefit, BenefitError } from '@1kit/ui/types/Benefit'
import { BenefitErrorCode } from '@1kit/ui/enums/BenefitErrorCode'

export type Props = {
  className?: string,
  benefit?: Benefit,
  error?: BenefitError
}

const ErrorText = styled(TypographyBase)`
  color: ${({ theme }) => theme.text.error};
`
const Typography = styled(TypographyBase)``
const CardContent = styled(CardContentBase)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
`

const ErrorIcon = styled(ErrorIconBase)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.text.error};
`

type ErrorDetails = {
  title: string,
  subtitle: string
}

const getErrorDetails = (
  error: BenefitError | undefined,
  benefit: Benefit | undefined,
): ErrorDetails => {
  const unavailableErrorObj = {
    title: 'There was an issue validating your digital collectible',
    subtitle: `Confirm that you own ${benefit?.series?.name ? `a ${benefit?.series?.name}` : 'this'} digital collectible and try again`,
  }

  const unknownErrorObj = {
    title: 'Something went wrong...',
    subtitle: 'There was a problem when trying to make the request. Please refresh and try again.',
  }

  const errorDetailsMap = {
    [BenefitErrorCode.UNAVAILABLE]: unavailableErrorObj,
    [BenefitErrorCode.NOT_FOUND]: unavailableErrorObj,
    [BenefitErrorCode.UNKNOWN]: unknownErrorObj,
    [BenefitErrorCode.AMAZON_TOKEN_MISSING]: unknownErrorObj,
    [BenefitErrorCode.EXPIRED]: {
      title: 'Benefit expired',
      subtitle: `Sorry, the last day for claiming this benefit ${benefit?.expires_at ? `was ${format(benefit.expires_at)}` : 'has passed'}`,
    },
  }

  return (
    error?.details?.code
      ? errorDetailsMap[error.details.code]
      : errorDetailsMap[BenefitErrorCode.UNKNOWN]
  )
}

function ClaimErrorComponent({ className, benefit, error } : Props) {
  const errorDetails = getErrorDetails(error, benefit)

  return (
    <CardContent className={className}>
      <ErrorIcon />
      <ErrorText variant="h6">{errorDetails.title}</ErrorText>
      <Typography variant="body1">{errorDetails.subtitle}</Typography>
      <AmazonButton full>
        {'Go to your amazon collectibles'}
      </AmazonButton>
    </CardContent>
  )
}

export default ClaimErrorComponent
