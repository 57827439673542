import { useEffect, useState } from 'react'
import classNames from 'classnames'
import NextLink from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

// ** Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'

const HeroCardBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.spacing(2.5),
  height: 480,
  width: '100%',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  '& .info': {
    position: 'absolute',
    top: '20%',
    left: 50,
    width: 600,
  },
  [theme.breakpoints.down('md')]: {
    height: 450,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .info': {
      left: '5%',
      width: '90%',
    },
  },
}))

const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}))

const ThumbnailWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: `${theme.spacing(-12)} auto 0`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  gap: theme.spacing(4),
  zIndex: 1,
  '.active-thumb': {
    width: '100%',
    transition: 'width 5s ease 1s',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

function HeroCard({ game }) {
  return (
    <HeroCardBox sx={{ width: '100%' }}>
      {/* eslint-disable-next-line */}
      <img src={game.image} alt={game.title} />
      <Box className="info">
        <Typography variant="h3" sx={{ mt: 2, color: 'white', mb: 2 }}>
          {game.title}
        </Typography>
        <Typography variant="h6" sx={{ mt: 2, color: 'white', mb: 9 }}>
          {game.description}
        </Typography>
        <NextLink href={`/collectibles/${game.id}`}>
          <Button variant="contained">{'Get in-game content'}</Button>
        </NextLink>
      </Box>
    </HeroCardBox>
  )
}

function ThumbCard({ active, game, onClick }) {
  return (
    <Box
      sx={{
        cursor: 'pointer', borderRadius: 1, width: 144, height: 96, position: 'relative', overflow: 'hidden',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          background: 'black',
          opacity: 0.5,
          zIndex: 0,
        }}
      />
      <StyledImage src={game.thumbnail} alt={game.title} sx={{ position: 'absolute', zIndex: 1 }} />
      {!active && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'black',
            opacity: 0.5,
            zIndex: 2,
          }}
        />
      )}
      <Box
        sx={{
          width: '0%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          background: 'black',
          opacity: 0.5,
          zIndex: 2,
        }}
        className={classNames({
          'active-thumb': active,
        })}
      />
    </Box>
  )
}

function FeaturedContentSlider({ featuredRewards }) {
  const [swiper, setSwiper] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(-1)

  useEffect(() => {
    setTimeout(() => {
      setCurrentIndex(0)
    }, 10)
  }, [])

  const handleSlideChange = (e) => {
    setCurrentIndex(e.realIndex)
  }

  const navigate = (index) => {
    swiper.slideToLoop(index, 1000)
  }

  return (
    <div>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop
        speed={1000}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={(e) => handleSlideChange(e)}
        onSwiper={(swiperItem) => setSwiper(swiperItem)}
      >
        {featuredRewards.map((game) => (
          <SwiperSlide key={game.id}>
            <HeroCard game={game} />
          </SwiperSlide>
        ))}
      </Swiper>
      <ThumbnailWrapper>
        {featuredRewards.map((game, index) => (
          <ThumbCard
            key={game.id}
            active={index === currentIndex}
            game={game}
            onClick={() => navigate(index)}
          />
        ))}
      </ThumbnailWrapper>
    </div>
  )
}

export default FeaturedContentSlider
