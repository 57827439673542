import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2.5),
}))

function CollectibleCardWithImageSkeleton() {
  return (
    <StyledCard>
      <Skeleton variant="rectangle" animation="wave" sx={{ height: 176 }} />
      <Box
        sx={{
          py: 3,
          px: 4,
          backgroundColor: 'customColors.tableHeaderBg',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Skeleton variant="text" animation="wave" sx={{ fontSize: '14px', width: '60%' }} />
      </Box>
      <Box sx={{ py: 8, px: 4 }}>
        <Skeleton variant="text" animation="wave" sx={{ fontSize: '14px', width: '40%' }} />
        <Skeleton variant="text" animation="wave" sx={{ fontSize: '20px', width: '70%' }} />
      </Box>
      <Button fullWidth disabled variant="contained" sx={{ borderRadius: 0 }}>
        Claim
      </Button>
    </StyledCard>
  )
}

export default CollectibleCardWithImageSkeleton
