import { useMutation, UseMutationOptions, UseMutationResult } from '@1kit/ui/data'
import { SubscriptionFrequency, SubscriptionType } from '@1kit/types/Subscription'
import { request } from '@1kit/api/request'

type Response = {
  url: string
}

type Props = {
  type?: SubscriptionType
  frequency: SubscriptionFrequency
  opts?: UseMutationOptions
}

export default function useCheckout({
  type,
  frequency,
  opts,
} : Props): UseMutationResult<Response, Error, unknown> {
  return useMutation<Response, Error, unknown>({
    mutationFn: () => request(
      '/billing/checkout',
      {
        method: 'POST',
        body: {
          type,
          frequency,
        },
        ...opts,
      },
    ),
  })
}
