import { useCallback } from 'react'
// ** Next Imports
import Head from 'next/head'
import { Router } from 'next/router'
import { KitProvider } from '@1kit/ui'

import 'core-js/actual/object/has-own'

// ** Loader Import
import NProgress from 'nprogress'

// ** Config Imports
import 'configs/i18n'
import themeConfig from 'configs/themeConfig'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'

// ** Component Imports
import UserLayout from 'layouts/UserLayout'
import Guard from 'components/auth/Guard'

import WindowWrapper from 'components/WindowWrapper'

import { ErrorBoundary } from '@1kit/ui/components/ErrorBoundary'

import ReactHotToast from 'materialize/styles/libs/react-hot-toast'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css' // Core theme CSS
import 'components/quillCustom.css'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Global css styles
import '../../styles/globals.css'
import DevkinToolsOverlay, { useDevkinWindowTools } from '@1kit/ui/devkin'

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

// ** Configure JSS & ClassName
function App(props) {
  useDevkinWindowTools()
  const { Component, pageProps } = props

  // Variables
  const getLayout = useCallback((page) => (
    Component.getLayout?.(page) ?? <UserLayout>{page}</UserLayout>
  ), [Component?.displayName])

  return (
    <WindowWrapper>
      <KitProvider>
        <ErrorBoundary>
          <DevkinToolsOverlay />
          <Head>
            <title>{'GR1D Terminal: Discover games and claim digital collectibles.'}</title>
            <meta
              name="description"
              content="GR1D Terminal is a content discovery destination for gamers.  Claim free digital collectibles and join the community."
            />

            <meta content="GR1D Terminal: Discover games and claim digital collectibles." key="og:title" property="og:title" />
            <meta
              content="GR1D Terminal is a content discovery destination for gamers.  Claim free digital collectibles and join the community."
              key="og:description"
              property="og:description"
            />
            <meta
              content={`${process.env.NEXT_PUBLIC_APP_URL}/images/opengraph-image.png`}
              key="og:image"
              property="og:image"
            />

            <meta name="keywords" content="1Kin Labs, 1Kin, Digital Collectibles, GR1D, Terminal, web3, games" />
            <meta name="viewport" content="initial-scale=1, width=device-width" />
          </Head>

          <Guard component={Component}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {getLayout(<Component {...pageProps} />)}
          </Guard>
          <ReactHotToast>
            <Toaster position="top-right" toastOptions={{ className: 'react-hot-toast' }} />
          </ReactHotToast>
        </ErrorBoundary>
      </KitProvider>
    </WindowWrapper>
  )
}

export default App
