/* eslint-disable @next/next/no-img-element */
import { useState } from 'react'
import { styled } from '@1kit/ui/theme'
import { Benefit, BenefitError } from '@1kit/ui/types/Benefit'
import { ErrorBoundary, useErrorBoundary } from '@1kit/ui/components/ErrorBoundary'
import {
  Card, CardContent as CardContentBase, Typography as TypographyBase,
} from '@mui/material'
import { format } from '@1kit/ui/formatters/date'
import DangerDiv from '@1kit/ui/atoms/DangerDiv'
import AmazonLogin, { Scopes, AmazonUser } from '@1kit/ui/components/SocialButtons/Amazon'

import ClaimErrorComponent from './ClaimErrorComponent'

export type Props = {
  benefit: Benefit,
  className?: string
}

const CardContent = styled(CardContentBase)``
const Typography = styled(TypographyBase)``

export type ClaimCardContentProps = Props & {
  onError: (e : BenefitError) => void
}

const ClaimCardContent = styled(({ className, benefit, onError } : ClaimCardContentProps) => {
  const { showBoundary } = useErrorBoundary()

  const onLoginSuccess = async (user : AmazonUser) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      // await auth.amazonRedemption(benefit.amazon_id, user)
    } catch (e) {
      showBoundary(e)
      onError(e as BenefitError)
    }
  }

  return (
    <CardContent className={className}>
      <Typography variant="h6">
        {`Claim your benefit by ${format(benefit.expires_at)}`}
      </Typography>
      <DangerDiv htmlString={benefit.series.claim_instructions || ''} />
      <AmazonLogin full onLogin={onLoginSuccess} scope={[Scopes.profile, Scopes.sandbox]} />
    </CardContent>
  )
})`

    display: flex;
    flex-direction: column;
    gap: 16px;

  ${DangerDiv} {
    margin-left: 16px;
  }
`

const ClaimCard = styled(({ className, benefit } : Props) => {
  const [error, setError] = useState<BenefitError | undefined>()

  return (
    <Card className={className}>
      <ErrorBoundary fallback={<ClaimErrorComponent benefit={benefit} error={error} />}>
        <ClaimCardContent benefit={benefit} onError={setError} />
      </ErrorBoundary>
    </Card>
  )
})`

  width: 100%;
`

export default ClaimCard
