/* eslint-disable @next/next/no-img-element */
import { SubscriptionFrequency } from '@1kit/types/Subscription'
import { styled, useTheme } from '@1kit/ui/theme'
import {
  Typography,
  Switch,
} from '@mui/material'

export type Props = {
  className?: string
  frequency: SubscriptionFrequency
  onChange: (newFrequency: SubscriptionFrequency) => void
}

const ToggleContainer = styled.div``

const PricingFrequencyToggle = styled(({ className, frequency, onChange } : Props) => {
  const theme = useTheme()
  const isAnnualFrequency = frequency === SubscriptionFrequency.ANNUAL

  const onToggleSwitch = (checked: boolean) => {
    onChange(checked ? SubscriptionFrequency.ANNUAL : SubscriptionFrequency.MONTHLY)
  }

  return (
    <div className={className}>
      <Typography variant="h6" fontWeight={500}>
        {'Need more credits?'}
      </Typography>
      <Typography variant="body2">
        {'Subscribe to our monthly or annual plan and you’ll have all the credits you could ever dream of.'}
      </Typography>
      <ToggleContainer>
        <Typography
          variant="body2"
          color={!isAnnualFrequency ? theme.border.primary.active : 'inherit'}
        >
          {'Pay Monthly'}
        </Typography>
        <Switch
          defaultChecked={isAnnualFrequency}
          onChange={(e, checked) => onToggleSwitch(checked)}
        />
        <Typography
          variant="body2"
          color={isAnnualFrequency ? theme.border.primary.active : 'inherit'}
        >
          {'Pay Yearly save 20%'}
        </Typography>
      </ToggleContainer>
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  max-width: 400px;
  padding: 16px;

  ${ToggleContainer} {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`

export default PricingFrequencyToggle
