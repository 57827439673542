/* eslint-disable @next/next/no-img-element */
import { styled } from '@1kit/ui/theme'
import {
  Card, CardContent as CardContentBase, CardMedia as CardMediaBase, Typography,
} from '@mui/material'
import { Benefit } from '@1kit/ui/types/Benefit'
import { Reward as RewardType } from '@1kit/ui/types/Series'

export type RewardProps = {
  reward: RewardType,
  className?: string
}

const CardMedia = styled(CardMediaBase)``
const CardContent = styled(CardContentBase)``

export const Reward = styled(({ reward, className } : RewardProps) => (
  <Card className={className}>
    <CardMedia image={reward.image} />
    <CardContent>
      <Typography variant="h6" textAlign="center">
        {reward.description}
      </Typography>
    </CardContent>
  </Card>
))`

  width: 100%;

  ${CardMedia} {
    height: 140px;
  }
`

export type Props = {
  benefit: Benefit
}

function Rewards({ benefit } : Props) {
  return (
    <>
      {benefit.series.rewards.map((reward) => <Reward reward={reward} key={reward.description} />)}
    </>
  )
}

export default Rewards
