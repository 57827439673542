import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import ChevronLeft from 'mdi-material-ui/ChevronLeft'
import ChevronRight from 'mdi-material-ui/ChevronRight'

// ** Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import GameCardWithImage from 'components/collectibles/CollectibleCardWithImage'
import GameCardWithImageSkeleton from 'components/collectibles/CollectibleCardWithImageSkeleton'

const PrevButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: theme.spacing(-4),
  transform: 'translateY(-50%)',
}))

const NextButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  right: theme.spacing(-4),
  transform: 'translateY(-50%)',
}))

function CollectibleSlider({ collectibles, onClickCollectible, loading }) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const [swiper, setSwiper] = useState(null)
  const [isBeginning, setIsBeginning] = useState(false)
  const [isEnd, setIsEnd] = useState(false)

  const handleSlideChange = (e) => {
    setIsBeginning(e.isBeginning)
    setIsEnd(e.isEnd)
  }

  return (
    <Box sx={{
      px: mobile ? 0 : 10, py: 4, margin: '0 auto', position: 'relative',
    }}
    >
      {/* {swiper?.allowSlidePrev && ( */}
      {!mobile && (
        <PrevButton disabled={isBeginning} onClick={() => swiper.slidePrev()}>
          <ChevronLeft fontSize="large" />
        </PrevButton>
      )}
      {/* )} */}
      <Swiper
        spaceBetween={16}
        slidesPerView={1}
        breakpoints={{
          320: {
            slidesPerView: 1.25,
          },
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }}
        onSlideChange={(e) => handleSlideChange(e)}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        {loading
          && [...Array(6)].map((_, index) => (
            <SwiperSlide key={index}>
              <GameCardWithImageSkeleton />
            </SwiperSlide>
          ))}
        {!loading
          && collectibles.map((collectible, index) => (
            <SwiperSlide key={index}>
              <GameCardWithImage collectible={collectible} onClickCollectible={onClickCollectible} />
            </SwiperSlide>
          ))}
      </Swiper>
      {/* {swiper?.allowSlideNext && ( */}
      {!mobile && (
        <NextButton disabled={isEnd} onClick={() => swiper.slideNext()}>
          <ChevronRight fontSize="large" />
        </NextButton>
      )}
      {/* )} */}
    </Box>
  )
}

export default CollectibleSlider
