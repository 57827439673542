/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect } from 'react'
import { useRouter, useSearchParams, usePathname } from 'next/navigation'
import { ReferralRequest } from '@1kit/types/Referral'
import { setReferralLocalStorage } from '@1kit/api/utils/localStorage'

type Props = {
  children: React.ReactNode
}

function WindowWrapper({ children }: Props) {
  // ** State
  const [windowReadyFlag, setWindowReadyFlag] = useState(false)
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const router = useRouter()
  useEffect(
    () => {
      if (typeof window !== 'undefined') {
        setWindowReadyFlag(true)
        const referral = searchParams.has('ref') ? {
          ref: searchParams.get('ref'),
          utmCampaign: searchParams.get('utm_campaign'),
          utmContent: searchParams.get('utm_content'),
          utmMedium: searchParams.get('utm_medium'),
          utmSource: searchParams.get('utm_source'),
          utmTerm: searchParams.get('utm_term'),
        } as ReferralRequest : null

        if (referral) {
          setReferralLocalStorage(referral)
          router.push(pathname)
        }
      }
    },
    [pathname],
  )
  if (windowReadyFlag) {
    return children
  }
  return null
}

export default WindowWrapper
