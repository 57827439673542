import { Typography } from '@mui/material'
import { styled } from '@1kit/ui/theme'
import { SubscriptionFrequency } from '@1kit/types/Subscription'
import { PricingCardPrice } from './PricingCardPrice'
import { BillingDescription } from './BillingDescription'

type Props = {
  className?: string
  price: number
  frequency: SubscriptionFrequency
}
export const PricingInfoSideBySide = styled(({ className, price, frequency }: Props) => (
  <div className={className}>
    <PricingCardPrice>{Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(price)}</PricingCardPrice>
    {
      price !== 0 && (
        <BillingDescription>
          <Typography variant="overline" lineHeight="15px">{'PER MONTH'}</Typography>
          <Typography
            variant="overline"
            lineHeight="15px"
          >
            {`BILLED ${frequency === SubscriptionFrequency.ANNUAL ? 'ANNUALLY' : 'MONTHLY'}`}
          </Typography>
        </BillingDescription>
      )
    }
  </div>
))`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(2)};
`
