import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import toast from 'react-hot-toast'

export const showSuccess = () => {
  toast(
    () => (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '#000',
        }}
      >
        <CheckCircleIcon fontSize="small" sx={{ mr: 2, color: 'success.main' }} />
        <Typography variant="body2" sx={{ color: '#ffffff' }}>
          {'Changes Saved.'}
        </Typography>
      </Box>
    ),
    {
      style: { minWidth: '300px', backgroundColor: '#000' },
      position: 'bottom-center',
    },
  )
}

export const showAddedToCollectionSuccess = () => {
  toast(
    () => (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '#000',
        }}
      >
        <CheckCircleIcon fontSize="small" sx={{ mr: 2, color: 'success.main' }} />
        <Typography variant="body2" sx={{ color: '#ffffff' }}>
          {'Success! This has been added to your collection.'}
        </Typography>
      </Box>
    ),
    {
      style: { minWidth: '300px', backgroundColor: '#000' },
      position: 'bottom-center',
    },
  )
}

export const showError = (message) => {
  toast(
    () => (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '#000',
        }}
      >
        <ErrorOutlineIcon fontSize="small" sx={{ mr: 2, color: 'error.main' }} />
        <Typography variant="body2" sx={{ color: '#ffffff' }}>
          {message || 'Something went wrong.'}
        </Typography>
      </Box>
    ),
    {
      style: { minWidth: '300px', backgroundColor: '#000' },
      position: 'bottom-center',
    },
  )
}
