import NextImage from 'next/image'
import NextLink from 'next/link'
import moment from 'moment'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { classNames } from '@1kit/ui/utils/classNames'
import { useTheme, styled } from '@1kit/ui/theme'
import { ClaimStatus } from '@1kit/ui/enums/ClaimStatus'

const CollectibleCardWithImage = styled(({ className, collectible, onClickCollectible }) => {
  const theme = useTheme()

  const daysUntilExpiration = moment(collectible.end_date).diff(moment(), 'd')
  const shouldShowExpiresSoon = daysUntilExpiration > 0 && daysUntilExpiration <= 60
  const shouldShowImportantExpiresSoon = daysUntilExpiration <= 7

  const daysSinceStart = Math.abs(moment(collectible.start_date).diff(moment(), 'd'))
  const shouldShowNewTag = daysSinceStart <= 7
  const inProgress = collectible?.claim?.status === ClaimStatus.IN_PROGRESS

  return (
    <div className={className}>
      <Box onClick={() => onClickCollectible(collectible)} sx={{ flex: 1 }}>
        <ImageBox>
          {collectible?.assets?.cardMedia?.defaultMedia?.src1x && (
            <NextImage
              layout="fill"
              src={collectible?.assets?.cardMedia?.defaultMedia?.src1x}
              alt={collectible?.assets?.cardMedia?.alt}
              objectFit="cover"
            />
          )}
        </ImageBox>
        <Box
          sx={{
            py: 3,
            px: 4,
            height: '44px',
            backgroundColor: 'customColors.tableHeaderBg',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            boxSizing: 'border-box',
          }}
        >
          {shouldShowExpiresSoon && (
            <>
              <Typography variant="body2" sx={{ color: 'text.primary', mr: 1 }}>
                {'Ends in'}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 700, color: shouldShowImportantExpiresSoon ? 'error.main' : 'text.primary' }}>
                {daysUntilExpiration}
                {' '}
                {'Days'}
              </Typography>
            </>
          )}
          {
            shouldShowNewTag && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '5px',
                  backgroundColor: theme.surface.warning,
                  padding: '0 8px',
                  ml: 2,
                }}
              >
                <Typography variant="caption" sx={{ fontWeight: 500, lineHeight: '20px', color: theme.text.light }}>
                  {'New'}
                </Typography>
              </Box>
            )
          }
        </Box>
        <Box sx={{ py: 8, px: 4 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {collectible.game.title}
          </Typography>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            {collectible.title}
          </Typography>
        </Box>
      </Box>
      <NextLink href={`/collectibles/${collectible.id}`}>
        <ClaimButton
          className={classNames({ claimed: inProgress })}
          fullWidth
          variant="contained"
          disabled={inProgress}
        >
          {inProgress ? 'SUCCESSFULY CLAIMED' : 'Claim'}
        </ClaimButton>
      </NextLink>
    </div>
  )
})`
  overflow: hidden;
  background-color: ${({ theme }) => theme.surface.paper};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radius};
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ImageBox = styled(Box)`
  position: relative;
  width: 100%;
  height: 176px;
`

const ClaimButton = styled(Button)`
  border-radius: 0;
  &.claimed {
    background-color: ${({ theme }) => theme.button.disabled};
    color: ${({ theme }) => theme.text.disabled};
  }
  background-color: ${({ theme }) => theme.button.primary};
  color: ${({ theme }) => theme.button.text};
`

export default CollectibleCardWithImage
