/* eslint-disable @next/next/no-img-element */
import { styled } from '@1kit/ui/theme'
import { Benefit } from '@1kit/ui/types/Benefit'

export type Props = {
  benefit: Benefit,
  className?: string
}

const Logo = styled(({ benefit, className } : Props) => (
  <img className={className} src={benefit.series.logo} alt="benefit logo" />
))`
  width: 100%;
  height: 128px;
  border-radius: 10px;
  object-fit: cover;
`

export default Logo
