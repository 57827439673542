import { request, RequestTypes } from '@1kit/api'
import { useMutation, useQueryClient } from '@1kit/ui/data'
import { useInvalidateCurrentUser } from '@1kit/ui/data/user'
import { createReward, updateReward } from 'utils/apiv2/fetchRewards'
import { startRewardClaimAsClaimV1 } from 'utils/apiv2/startRewardClaim'
import { Reward } from 'utils/apiv2/types'

export function useClaimRewardMutation(id: number | undefined) {
  const client = useQueryClient()
  const invalidateCurrentUser = useInvalidateCurrentUser()
  return useMutation({
    mutationKey: ['/rewards/claim', id],
    async onSuccess() {
      invalidateCurrentUser()
      return Promise.all([
        client.invalidateQueries({ queryKey: ['/rewards', id] }),
        client.invalidateQueries({ queryKey: ['/rewards'] }),
      ])
    },
    async mutationFn() {
      if (id) {
        return startRewardClaimAsClaimV1(id)
      }
      return undefined
    },
  })
}

export function useRewardMutation(id?: number) {
  const client = useQueryClient()
  return useMutation({
    mutationKey: ['updateReward', id],
    onSuccess() {
      return Promise.all([
        client.invalidateQueries({ queryKey: ['/rewards', id] }),
        client.invalidateQueries({ queryKey: ['/rewards'] }),
      ])
    },
    mutationFn({ reward, codes }: {reward: Partial<Reward>, codes?: string[]}) {
      if (id) {
        return updateReward(id, reward, codes)
      }
      return createReward(reward, codes)
    },
  })
}

export function useApproveRewardMutation() {
  const client = useQueryClient()
  return useMutation({
    mutationKey: ['approveReward'],
    onSuccess() {
      return client.invalidateQueries({ queryKey: ['/rewards'] })
    },
    mutationFn(id: number) {
      return request<Reward>(`/rewards/${id}/approve`, { method: 'POST', body: {} })
    },
  })
}
