// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu'

// ** Components
import UserDropdown from 'materialize/layouts/components/shared-components/UserDropdown'
import { useAuth } from '@1kit/ui/context/auth'
import CreditsButton from 'components/credits/CreditsButton'
import { useFlags } from '@1kit/ui/flags'

function AppBarContent(props) {
  // ** Props
  const { hidden, settings, toggleNavVisibility } = props
  const { user, logout } = useAuth()
  const flags = useFlags()
  const showCredits = flags.glassWindow && user

  return (
    <Box sx={{
      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
    }}
    >
      <Box className="actions-left" sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color="inherit" sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
        ) : null}
      </Box>
      <Box className="actions-right" sx={{ display: 'flex', alignItems: 'center' }}>
        {showCredits && <CreditsButton user={user} />}
        <UserDropdown settings={settings} user={user} logout={logout} />
      </Box>
    </Box>
  )
}

export default AppBarContent
