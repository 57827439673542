/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps, Typography } from '@mui/material'
import { styled, useTheme } from '@1kit/ui/theme'

export const PricingCardBadge = styled(({ className, children }: BoxProps) => {
  const theme = useTheme()

  return (
    <Box className={className}>
      <Typography
        variant="overline"
        fontSize={12}
        lineHeight="15px"
        color={theme.surface.paperLight}
      >
        {children}
      </Typography>
    </Box>
  )
})`
  && {
    background-color: ${({ theme }) => theme.border.primary.active};
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 8px;
    line-height: 1;
    border-radius: 0 0 0 5px;
  }
`
