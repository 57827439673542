import { styled } from '@1kit/ui/theme'
import { ChangeEvent, useState } from 'react'
import { Logo } from '@1kit/ui/components/Logo'
import { TextField, Button, Typography } from '@mui/material'

export type Props = {
  className?: string,
  onChange: (code: string) => void
}

const CodeContainer = styled.div``

const GateEntryPage = styled(({
  className,
  onChange
} : Props) => {
  const [code, setCode] = useState('')

  const onTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCode(e.target.value)
  }

  return (
    <div className={className}>
      <Logo />
      <Typography>
        {'Connect to the GR1D by entering the secret code....'}
      </Typography>
      <CodeContainer>
        <TextField onChange={onTextChange} />
        <Button onClick={() => onChange(code)} variant="contained">Connect</Button>
      </CodeContainer>
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 24px;

  ${CodeContainer} {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
`

export default GateEntryPage
