import { useState } from 'react'

import LoginDialog from '@1kit/ui/components/LoginDialog'

import * as Sentry from '@sentry/nextjs'
import Collectible from '@1kinlabs/ui-core/pages/Collectible'
import { showAddedToCollectionSuccess, showError } from 'utils/toast'
import { useClaimRewardMutation } from 'data/rewards/mutation'
import OnePassDialog from 'components/OnePassDialog'
import { User } from '@1kit/types'
import { OnAddToCollection } from '@1kinlabs/ui-core/components/collectible/ClaimInfo/ClaimCard'
import { CollectibleBlopBlop, LegacyGameBlopBlop } from 'utils/apiv2/transformations'
import { SubscriptionType } from '@1kit/types/Subscription'

export type Props = {
  user: User | null,
  game: LegacyGameBlopBlop,
  collectible: CollectibleBlopBlop
}

function CollectiblePage({ user, collectible, game } : Props) {
  const [shouldShowLoginDialog, setShouldShowLoginDialog] = useState(false)
  const [shouldOnePassDialogBeOpen, setShouldOnePassDialogBeOpen] = useState(false)
  const { mutateAsync } = useClaimRewardMutation(collectible?.id)

  const canUserClaim = () => {
    if (!user) {
      return false
    }

    if (user?.role === 'admin') {
      return true
    }

    if (game.accountId === user.accountId) {
      return true
    }

    if (user.subscription?.type === SubscriptionType.UNLIMITED) {
      return true
    }

    return user.availableCredits! >= collectible.costInCredits!
  }

  const handleAddToCollection : OnAddToCollection = async (c, setIsLoading) => {
    if (!user) {
      setShouldShowLoginDialog(true)
      return
    }

    if (canUserClaim()) {
      await claimCollectible(c, setIsLoading)
    } else {
      setShouldOnePassDialogBeOpen(true)
    }
  }

  const claimCollectible : OnAddToCollection = async (c, setIsLoading) => {
    try {
      setIsLoading(true)
      await mutateAsync()

      showAddedToCollectionSuccess()
    } catch (e: unknown) {
      Sentry.captureException(e)

      if (e instanceof Error) {
        Sentry.captureException(e)
        showError(e.message)
      } else {
        showError('An unknown error has occurred.')
      }
    }
  }

  return (
    <>
      <OnePassDialog
        open={shouldOnePassDialogBeOpen}
        onClose={() => setShouldOnePassDialogBeOpen(false)}
        collectible={collectible}
      />
      <Collectible
        collectible={collectible}
        game={game}
        claim={collectible?.claim}
        user={user}
        onAddToCollection={handleAddToCollection}
      />
      <LoginDialog
        open={shouldShowLoginDialog}
        onClose={() => setShouldShowLoginDialog(false)}
      />
    </>
  )
}

export default CollectiblePage
